import React, { useContext, useEffect } from "react";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ClassicTableDatasheet } from "../../../utils/components/Styles";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatasheetContext } from "./datasheet.context.js";
import {
  accuracyFormat,
  parseRange,
} from "../../../utils/components/accuracyandlcandrangesshow.js";

export default function DetailsOfCalStandards(props) {
  const {
    state: {
      standardMasterArray,
      selectedStandardIds,
      selectedStandardMasters,
      expiredStandardNames,
      datasheetDetails,
      selectedMastersIds,
    },
    dispatch,
  } = useContext(DatasheetContext);

  //validation Function
  const validateStandards = (type) => {
    let counter = 1;
    if (type === 1) {
      let expiredStandards = [];
      standardMasterArray.map((standard) => {
        if (moment().isAfter(standard.validUpto)) {
          expiredStandards.push(
            `${counter}. ${standard.stId} (${standard.standardName})`,
          );
          counter = counter + 1;
        }
      });
      return expiredStandards;
    }
  };

  const updateSelectedStandardMasters = (selectedStandardIds) => {
    // fun: assign standard master data from standardMasterArray to selectedStandardMasters based on selectedStandardIds
    let _selectedStandardIds = selectedStandardIds
      .map((id) => Number(id))
      .filter((id) => id > 0);

    if (!standardMasterArray || standardMasterArray?.length === 0) return;

    let tempSelectedStandardMasters = [];
    _selectedStandardIds.forEach((id) => {
      let standardMaster = standardMasterArray.find(
        (standard) => standard.id == id,
      );
      if (standardMaster) tempSelectedStandardMasters.push(standardMaster);
    });

    // set selectedStandardMasters
    dispatch({
      field: "selectedStandardMasters",
      value: [...tempSelectedStandardMasters],
    });
  };

  useEffect(() => {
    updateSelectedStandardMasters(selectedStandardIds);
  }, [selectedStandardIds, standardMasterArray]);

  useEffect(() => {
    if (selectedStandardMasters?.length > 0) {
      const expiredItems = selectedStandardMasters.filter(
        (row) =>
          moment().isAfter(moment(row.validUpto)) &&
          moment(datasheetDetails?.calibrationDate).isSameOrAfter(
            moment(row.validUpto),
          ),
      );

      // setUnValidMasterCount(expiredItems.length);

      let allStandardNames = expiredItems.map(
        (item, index) => `${index + 1}. ${item.stId} (${item.standardName})`,
      );

      dispatch({
        field: "expiredStandardNames",
        value: allStandardNames,
      });
    }
  }, [selectedStandardMasters]);

  return (
    <div>
      <>
        <Typography
          variant={props.isPrinting ? "body1" : "h6"}
          component="div"
          sx={{ mb: props.isPrinting ? 0 : 2, textAlign: "center" }}
        >
          DETAILS OF MASTER EQP USED FOR CALIBRATION
        </Typography>

        {!props.isPrinting && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "auto",
              marginBottom: "5px",
            }}
          >
            <Autocomplete
              multiple
              id="outlined-basic"
              size="small"
              className="Listinputcss"
              style={{ minWidth: "350px" }}
              options={standardMasterArray?.filter((row) =>
                moment(datasheetDetails?.calibrationDate).isSameOrBefore(
                  moment(row.validUpto),
                ),
              )}
              value={
                standardMasterArray?.filter((ele) =>
                  selectedMastersIds?.split(",")?.map(Number)?.includes(ele.id),
                ) || null
              }
              disableCloseOnSelect
              getOptionLabel={(option) =>
                `${option.stId} : ${option.standardName}` || ""
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.stId}: {option.standardName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Master EQP Selection"
                  // placeholder="Select Multiple Standard Masters"
                />
              )}
              onChange={(event, value) => {
                const selectedIds = value.map((option) => option.id)?.join(",");
                dispatch({
                  field: "selectedMastersIds",
                  value: selectedIds,
                });
              }}
            />
          </div>
        )}

        {selectedStandardMasters.length > 0 ? (
          <div style={{ width: "100%", overflow: "auto" }}>
            <ClassicTableDatasheet>
              <Table sx={{ minWidth: 660 , }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {selectedStandardMasters &&
                      selectedStandardMasters.length > 0 && (
                        <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Sr. No.</TableCell>
                      )}
                    {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row.standardName
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Name</TableCell>}
                    {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row?.stId || row.serialNo
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>ID No. / Serial No.</TableCell>}
                    {/* {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row.masterrange
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Range</TableCell>}
                    {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row.masteraccuracy
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Accuracy</TableCell>}
                    {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row.masterleastcount
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Least Count</TableCell>}
                    {!props.isPrinting && selectedStandardMasters &&
                      selectedStandardMasters.some((row) => row.make) && (
                        <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Make/Model</TableCell>
                      )} */}
                    {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row.certificateNumber
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Certificate No.</TableCell>}
                     {selectedStandardMasters &&
                      selectedStandardMasters.some((row) => row.validUpto) && (
                        <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Valid Upto</TableCell>)}
                    {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row.traceability
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Cal By / Accreditation Certificate No.</TableCell>}
                    {/* {selectedStandardMasters &&
                      selectedStandardMasters.some((row) => row.calDate) && (
                        <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Calibration Date</TableCell>
                      )} */}
                      {selectedStandardMasters &&
                      selectedStandardMasters.some(
                        (row) => row.masterrange
                      ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>Range</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedStandardMasters &&
                    selectedStandardMasters?.map((row, index) => (
                      <TableRow key={"standard-row-" + index}>
                        {selectedStandardMasters.length > 0 && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>{index + 1}</TableCell>
                        )}
                        {selectedStandardMasters.some(
                          (row) => row.standardName,
                        ) && (
                          <TableCell
                            style={{
                              color: `${
                                moment().isAfter(row?.validUpto) ? "red" : ""
                              }`,
                            }}
                          >
                            {row.standardName}
                          </TableCell>
                        )}
                        {selectedStandardMasters.some(
                          (row) => row.stId || row.serialNo,
                        ) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>
                            {row.stId && row.serialNo ? `${row.stId} / ${row.serialNo}` : row.stId || row.serialNo}
                          </TableCell>
                        )}
                        {/* {selectedStandardMasters.some(
                          (row) => row.masterrange
                        ) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>{parseRange(row.masterrange)}</TableCell>
                        )}
                        {selectedStandardMasters.some(
                          (row) => row.masteraccuracy,
                        ) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>
                            {accuracyFormat(row.masteraccuracy)}
                          </TableCell>
                        )}
                        {selectedStandardMasters.some(
                          (row) => row.masterleastcount,
                        ) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>
                            {parseRange(row.masterleastcount)}
                          </TableCell>
                        )}
                        {!props.isPrinting && selectedStandardMasters.some((row) => row.make) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>{row.make}</TableCell>
                        )} */}
                        {selectedStandardMasters.some(
                          (row) => row.certificateNumber
                        ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>{row.certificateNumber}</TableCell>}
                       
                        {/* {selectedStandardMasters.some((row) => row.calDate) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>
                            {moment(row.calDate).format("DD/MM/YYYY")}
                          </TableCell>
                        )} */}
                        {selectedStandardMasters.some(
                          (row) => row.validUpto,
                        ) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>
                            {moment(row.validUpto).format("DD/MM/YYYY")}
                          </TableCell>
                        )}
                         {selectedStandardMasters.some(
                          (row) => row.traceability
                        ) && <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>{row.traceability}</TableCell>}
                        {selectedStandardMasters.some(
                          (row) => row.masterrange
                        ) && (
                          <TableCell style={{textAlign:"center", fontFamily: "Times New Roman, serif",}}>{parseRange(row.masterrange)}</TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </ClassicTableDatasheet>
          </div>
        ) : null}
      </>
    </div>
  );
}
